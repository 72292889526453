.slider {
  background: $white;
  .container {
    .slick-arrow:before {
      border-color: $white;
    }
    .slideshow {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      .slick-list {
        width: 90%;
        order: 2;
      }
      .slick-track {
        display: flex;
      }
      .slide {
        height: inherit;
        display: flex !important;
        flex-flow: column nowrap;
        justify-content: space-between;
        > div {
          display: flex;
          flex-flow: row wrap;
          width: 100%;
          padding: 0 15px;
          justify-content: flex-start;
          align-items: flex-start;
          //height: 100%;
          .image {
            order: 1;
            overflow: hidden;
            width: 100%;
            height: 225px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            img {
              min-width: 100%;
              min-height: 100%;
              height: auto;
              width: auto;
              object-fit: cover;
              display: none;
            }
          }
          h3 {
            order: 2;
            font-size: 26px;
            width: 100%;
            line-height: 0.9;
            color: $white;
            margin: 20px 0 10px;
          }
          time {
            color: $white;
            font-size: 16px;
            margin: 0 0 15px;
            display: block;
            order: 3;
          }
          p {
            order: 4;
            line-height: 1.5;
            width: 100%;
            font-size: 16px;
            word-wrap: normal;
            margin: 0 0 10px;
          }
        }
        a.button {
          order: 5;
          background: $teal;
          color: $white;
          box-shadow: none;
          display: block;
          align-self: flex-end;
          margin: 20px 15px;
          padding: 10px 0;
          width: calc(100% - 30px);
          &:hover {
            background: $white;
            color: $teal;
          }
        }
      }
    }
  }

  @media screen and (max-width: 787px) {
    .container {
      font-size: 40px;
      h2 {
        &:after {
          content: "Swipe to view more";
          text-transform: uppercase;
          display: block;
          // font-family: $text;
          font-size: 18px;
          margin: 10px 0;
        }
      }

      a.button {
        margin: 20px auto !important;
      }
      .slideshow {
        .slick-arrow {
          display: none !important;
        }
        .slick-list {
          width: 100%;
        }
      }
    }
  }
}
