header {
  background: $white;
  padding: 15px 0;
  z-index: 999999;
  position: relative;
  &.uk-active {
    .logo {
      img {
        width: 400px;
      }
    }
  }
  .uk-container {
    @include flex(row, center, center, nowrap);
    position: relative;
  }
  .logo {
    width: 400px !important;
  }
  button {
    display: none;
  }
  nav {
    width: calc(100% - 200px);
    padding: 15px 0 50px 5px;
    @include flex(column, center, flex-end, nowrap);
    ul {
      @include flex(row, flex-end, center, nowrap);
      padding: 10px 0 0;
      margin: 0;
      li {
        padding: 10px;
        text-decoration: none;
        list-style-type: none;
        font-size: 21px;
        font-family: calibri, sans-serif;
        a {
          color: $slate;
          line-height: 1;
          display: block;
          padding: 0 5px;
          text-transform: uppercase;
          &:hover,
          &:active,
          &:focus,
          &:focus-within {
            color: $teal;
            svg * {
              fill: $teal;
            }
          }
        }
      }
    }
  }
  &.uk-active {
    @extend %box-medium;
    .logo {
      width: 200px;
    }
    nav {
      width: calc(100% - 150px);
    }
  }
  @media screen and (max-width: 1200px) {
    .uk-container {
      #main-menu {
        .navigation {
          li {
            font-size: 18px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1080px) {
    .uk-container {
      #main-menu {
        .navigation {
          li {
            font-size: 14px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    button {
      display: block;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      border: none;
      color: $teal;
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      svg {
        width: 30px;
      }
    }
    nav {
      position: fixed;
      background: $teal;
      max-width: 100%;
      width: 300px !important;
      padding: 50px 25px;
      top: 0;
      right: -300px;
      height: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      transition: right 0.4s ease;
      button {
        color: $white;
      }
      ul {
        width: 100%;
        justify-content: flex-start;
        li {
          font-size: 16px;
          a {
            color: $white;
            font-weight: bold;
            svg * {
              fill: $white;
            }
            &:hover,
            &:active,
            &:focus,
            &:focus-within {
              color: $orange;
              svg * {
                fill: $orange;
              }
            }
          }
        }
        &:not(.social-nav) {
          flex-direction: column;
          order: -1;
          align-items: flex-start;
        }
        &.social-nav {
          padding: 0 10px;
        }
      }
      &.open {
        right: 0;
        transition: right 0.4s ease;
      }
    }
  }
  @media screen and (max-width: 500px) {
    &.uk-active {
      .logo {
        img {
          width: 200px;
        }
      }
    }
    .logo {
      width: 200px !important;
      padding-right: 20px;
    }
  }
}
