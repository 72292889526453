footer {
  background: $teal;
  .top {
    @include flex(row, center, center, nowrap);
    padding-bottom: 100px;
    border-bottom: 2px solid $white;
    #contact {
      width: 50%;
      margin: 50px 0 0;
    }
    .company-info {
      @include flex(column, center, center, nowrap);
      text-align: center;
      padding: 0 0 0 50px;
      width: 50%;
      img {
        width: 450px;
      }
      a {
        color: $white;
        font-size: 0.75em;
        &:hover,
        &:active,
        &:focus,
        &:focus-within {
          background-color: $white;
          color: $teal;
          transition: all 0.4s ease;
        }
      }
      address {
        color: $white;
        margin: 30px 0 0;
        font-size: 0.75em;
      }
      a.button {
        background: $white;
        color: $teal;
        box-shadow: none;
        padding: 10px 60px;
        &:hover,
        &:active,
        &:focus,
        &:focus-within {
          background-color: $ltgreen;
          color: $white;
          text-decoration: none;
          transition: color 0.4s ease;
        }
      }
    }
  }

  .contact {
    justify-content: center;
    align-items: center;
    h2 {
      color: $white;
      font-size: 2em !important;
      padding: 50px 0 0 0;
    }
    ul.info {
      @include flex(row, center, center, nowrap);
      list-style-type: none;
      text-decoration: none;
      color: white;
      font-size: 12pt;
      padding-left: 0 !important;
      li {
        text-align: center;
        p,
        a {
          text-decoration: none;
          color: white;
          font-size: 10pt;
          line-height: 1.25em;
        }
        p.name {
          font-size: 18px;
          font-weight: bold;
        }
        a.email p,
        a.call p {
          &:hover,
          &:active,
          &:focus,
          &:focus-within {
            color: $slate;
            transition: color 0.4s ease;
          }
        }
      }
    }
  }
  #footer-utility-nav {
    font-size: 12px;
    padding: 10px 0;
    .uk-container {
      background-color: $teal;
      @include flex(row, center, center, wrap);
      p {
        color: $white;
        margin: 0;
        padding: 15px 0;
      }
    }
  }
  @media screen and (max-width: 1175px) {
    #company {
      #contact {
        padding: 30px;
      }
      .company-info {
        img {
          width: 200px;
        }
        h3,
        a,
        address {
          font-size: 1em;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    #company {
      flex-direction: column;
      #contact {
        width: 100%;
        padding: 10px 0;
        margin: 0px;
      }
      .company-info {
        padding: 100px 0 0;
        width: 100%;
      }
    }
    .contact {
      ul.info {
        flex-direction: column;
        li {
          margin-bottom: 30px;
        }
      }
    }
  }
}
