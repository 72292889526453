$black: #000000;
$white: #ffffff;
$silver: #c0c0c0;
$primary: $black;
$secondary: $silver;
$teal: #4aa9a3;
$ltgreen: #bfd32e;
$orange: #f07400;
$slate: #707070;

$font_headings: "";
$font_copy: "";

$font_size: 18px;

@import "_mixins.scss", "_animations.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none !important;
}

html,
body {
  width: 100%;
  height: 100%;
  position: relative;
}

.button {
  text-transform: uppercase;
  font-size: 14px;
  padding: 10px 50px;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  margin: 15px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  transition: background 0.4s ease, color 0.4s ease;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: background 0.4s ease, color 0.4s ease;
  }
}

.wrap {
  width: 100%;
  height: 100%;
  position: relative;
}

.noscroll {
  overflow: hidden;
}

section {
  width: 100%;
  position: relative;
}

.container {
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
}

img,
iframe,
video {
  max-width: 100%;
}

.hidden {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 700;
  font-style: normal;
}

p {
  font-family: montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
}

h2 {
  font-size: 80px;
  text-align: center;
  width: 100%;
  line-height: 1;
  padding: 0;
  margin: 0 0 50px;
}

.paralaxed-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 0;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: auto;
    min-height: calc(100% + 300px);
    min-width: 100%;
    max-width: none;
  }
}

.slick-arrow {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 100px 0;
  border: none;
  background: transparent;
  font-size: 0px;
  color: transparent;
  &:before {
    content: "";
    width: 30px;
    height: 30px;
    display: block;
    border: 3px solid $white;
    transform: rotate(45deg);
  }
  &.slick-prev {
    order: 1;
    &:before {
      border-right: none;
      border-top: none;
    }
  }
  &.slick-next {
    order: 3;
    &:before {
      border-left: none;
      border-bottom: none;
    }
  }
  &:hover:before,
  &:focus:before,
  &:active:before {
    border-color: $slate !important;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
