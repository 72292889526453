@mixin flex($d, $x, $y, $w) {
  display: flex;
  flex-direction: $d;
  justify-content: $x;
  align-items: $y;
  flex-wrap: $w;
}
	
@mixin size($w, $h) {
  width: $w;
  height: $h;
}
	
@mixin background($s, $p, $r) {
  background-size: $s;
  background-position: $p;
  background-repeat: $r;
}
	
%box-light {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.25s ease-in-out;
}
	
%box-medium {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.25s ease-in-out;
}
	
%box-heavy {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: all 0.25s ease-in-out;
}
	
%box-none {
  box-shadow: none;
  transition: all 0.25s ease-in-out;
}
	
%button, .uk-button {
  text-transform: uppercase;
  font-size: 0.8em;
  color: $white;
  padding: 10px 40px;
  letter-spacing: 2px;
  @extend %box-light;
  background-color: $primary;
  line-height: 1;
  font-weight: bold;
  transition: background 0.4s ease;
  border-radius: 5px;
  a {
	color: $white;
	display: block;
	text-align: center;
  }
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
	@extend %box-medium;
	color: $white;
	background-color: $secondary;
	transition: background 0.4s ease;
	a {
		color: $black;
	}
  }
  @media screen and (max-width: 500px) {
	width: 100%;
  }
}
	
%zoom-hover {
  transition: transform 0.2s ease-out;
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
	transform: scale(1.08);
	transition: transform 0.2s ease-out;
  }
}
	
%table, table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 50px 0 30px;
  th {
	padding: 16px 12px;
	text-align: left;
	vertical-align: bottom;
	font-size: 0.875rem;
	font-weight: 400;
	color: #999;
	text-transform: uppercase;
  }
  td {
	padding: 16px 12px;
	vertical-align: top;
	> :last-child {
	  margin-bottom: 0;
	}
  }
  .tfoot {
	font-size: 0.875rem;
  }
  caption {
	font-size: 0.875rem;
	text-align: left;
	color: #999;
  }
  tbody tr:nth-of-type(odd),
  > tr:nth-of-type(odd) {
	background: #f8f8f8;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
  }
  @media (max-width: 959px) {
	display: block;
	tbody,
	td,
	th,
	tr {
	  display: block;
	}
	thead {
	  display: none;
	}
	td,
	th {
	  width: auto !important;
	  max-width: none !important;
	  min-width: 0 !important;
	  overflow: visible !important;
	  white-space: normal !important;
	}
	td:not(:first-child):not(.uk-table-link),
	th:not(:first-child):not(.uk-table-link) {
	  padding-top: 5px !important;
	}
	td:not(:last-child):not(.uk-table-link),
	th:not(:last-child):not(.uk-table-link) {
	  padding-bottom: 5px !important;
	}
  }
}
	