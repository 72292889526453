#top {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 60px 0 0;
  background-color: $teal;
  overflow: hidden;
  position: relative;
  height: calc(100%);
  min-height: 650px;
  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.77;
    background-image: linear-gradient(
      270deg,
      rgba(238, 238, 238, 0) 0%,
      #687975 50%
    );
  }
  .container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100%;
    position: relative;
    z-index: 20;
    padding-bottom: 150px;
    h1 {
      color: $white;
      max-width: 1050px;
    }
    p {
      color: $white;
      font-size: 16px;
      line-height: 2;
      max-width: 750px;
    }
  }
}

#event {
  background: $teal;
  padding: 10px;
  .container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    div {
      padding: 0 40px;
      flex: 2;
    }
    h2 {
      color: $white;
      margin: 0;
      text-align: left;
      font-size: 3.5em;
    }
    p {
      color: $slate;
      margin: 0;
      font-weight: bold;
      font-size: 1.5em;
    }
    img {
      width: 100px;
      order: -1;
    }
  }
}
#about {
  .uk-container {
    @include flex(row, center, center, nowrap);
    .about-text {
      width: calc(100% - 600px);
      padding: 0 0 0 100px;
      .headline-copy {
        .button {
          &:hover,
          &:active,
          &:focus,
          &:focus-within {
            background-color: $ltgreen;
            color: $white;
            transition: all 0.4s ease;
          }
        }
      }
    }
    .abt-slideshow {
      width: 600px;
      .abt-items {
        width: 600px;
        height: 600px;
      }
      a {
        color: $slate;
        &:hover,
        &:active,
        &:focus,
        &:focus-within {
          color: $teal;
        }
        &.uk-slidenav-previous {
          left: -75px !important;
        }
        &.uk-slidenav-next {
          right: -75px !important;
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
    .uk-container {
      flex-direction: column;
      .about-text {
        width: 100%;
        padding: 50px 0 0;
      }
      .abt-slideshow {
        width: 600px;
        .abt-items {
          width: 600px;
          height: 600px;
        }
        a {
          &.uk-slidenav-previous {
            left: -50px !important;
          }
          &.uk-slidenav-next {
            right: -50px !important;
          }
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    .uk-container {
      flex-direction: column;
      .about-text {
        width: 100%;
        padding: 50px 0 0;
      }
      .abt-slideshow {
        width: 500px;
        .abt-items {
          width: 500px;
          height: 500px;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .uk-container {
      .about-text {
        width: 100%;
        .button {
          width: 100%;
        }
      }
      .abt-slideshow {
        width: 300px;
        .abt-items {
          width: 300px;
          height: 300px;
        }
      }
    }
  }
  @media screen and (max-width: 375px) {
    .uk-container {
      .abt-slideshow {
        width: 200px;
        .abt-items {
          width: 200px;
          height: 200px;
        }
      }
    }
  }
}
#amenities {
  background: $teal;
  background-size: cover;
  padding: 100px 0;
  .uk-dotnav {
    padding: 40px 0 0;
    display: none;
  }
  .uk-container {
    @include flex(row, center, center, wrap);
    position: relative;
    z-index: 1;
    h2 {
      color: $white;
      padding-bottom: 10px;
    }
    .amenities-slideshow {
      @include flex(row, center, center, wrap);
      ul {
        width: 100%;
        .slide {
          display: block;
          position: relative;
          width: 100%;
          padding: 150px 40px 40px;
          .text {
            background: $white;
            padding: 20px;
            width: 55%;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
            z-index: 1;
            position: relative;
            h3 {
              color: $teal;
              font-size: 40px;
              margin: 0 0 10px;
              line-height: 1;
            }
            p {
              line-height: 2;
              font-size: 16px;
            }
          }
          .image {
            width: 60%;
            z-index: 0;
            height: 100%;
            position: absolute;
            right: 0;
            bottom: 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            img {
              min-width: 100%;
              min-height: 100%;
              display: block;
              margin: 0 auto;
              box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
            }
          }
        }
      }
      a.uk-slidenav {
        padding: 10px 5px;
        position: absolute;
        margin: 0 5px;
        z-index: 20;
        svg {
          color: $white;
          z-index: 20;
          &:hover,
          &:active,
          &:focus,
          &:focus-within {
            color: $slate;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1275px) {
    .uk-container {
      .amenities-slideshow {
        .slide {
          .text {
            width: 55%;
          }
          .image {
            width: 65%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .uk-dotnav {
      display: flex;
    }
    .uk-container {
      .amenities-slideshow ul {
        .slide {
          display: flex !important;
          flex-direction: row;
          flex-wrap: wrap !important;
          padding: 0;
          .text {
            width: 100%;
            order: 2;
            box-shadow: none;
            transform: none !important;
            opacity: 1 !important;
          }
          .image {
            width: 100%;
            position: relative;
            right: auto;
            z-index: 0;
            top: auto;
            height: auto;
            padding: 50% 0 0;
            order: 1;
            opacity: 1 !important;
            transform: none !important;
          }
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    .uk-container {
      h2 {
        font-size: 60px;
      }
      .amenities-slideshow ul {
        .slide {
          .text {
            padding: 40px;
            font-size: 26px;
            transform: none !important;
          }
          .image {
            transform: none !important;
            margin: 0 auto 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 787px) {
    .uk-container {
      h2 {
        &:after {
          content: "Swipe to view more";
          text-transform: uppercase;
          display: block;
          font-family: calibri, sans-serif;
          font-size: 18px;
          margin: 10px 0;
        }
      }
      .amenities-slideshow {
        a.uk-slidenav {
          display: none !important;
        }
      }
    }
  }
  @media screen and (max-width: 660px) {
    .uk-container {
      .arrow {
        display: none;
      }
      .amenities-slideshow {
        width: 100%;
      }
    }
  }
}

#floorplan {
  .plans {
    h2 {
      color: $black;
      font-weight: bold;
      padding: 50px 0 30px 0;
    }
    .grid {
      border-radius: 10px;
      margin: 40px 0;

      .image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
      }
      .uk-card-body {
        h3 {
          color: $teal;
        }
        .button {
          &:hover,
          &:active,
          &:focus,
          &:focus-within {
            background-color: $ltgreen;
            color: $white;
            transition: all 0.4s ease;
          }
        }
      }
    }
  }
  .cta {
    .button {
      &:hover,
      &:active,
      &:focus,
      &:focus-within {
        background-color: $ltgreen;
        color: $white;
        transition: all 0.4s ease;
      }
    }
  }
}
