* {
  box-sizing: border-box;
  .button {
    background-color: $teal;
    color: $white;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 10px 10px;
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      background-color: $ltgreen;
      color: $white;
      transition: all 0.4s ease;
      text-decoration: none;
    }
  }
}

html,
body {
  width: 100%;
  font-size: $font_size;
} //default $font_size is declared in _variables.scss
em {
  color: inherit;
} //removes uikit em red style and replaces it with theme style
p a,
li a {
  text-decoration: none !important;
} //removes pesky text-decorations

// ---- Project Styles Begin Here ---- //
h1,
h2 {
  color: $teal;
  text-transform: uppercase;
  line-height: 1;
  margin: 0 0 5px;
  font-size: 2.8em;
  font-family: "yorkten-slab-extended", sans-serif;
  & + h2,
  & + h3,
  & + .headline-copy h2 {
    font-family: calibri, sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $silver;
    font-size: 1.5em;
    margin: 0 0 20px;
    line-height: 1;
  }
  @media screen and (max-width: 992px) {
    font-size: 2em;
    & + h2,
    & + h3 {
      font-size: 1.2em;
    }
  }
}
p {
  letter-spacing: 0px;
  color: $slate;
  font-family: calibri, sans-serif;
  line-height: 2em;
}

.uk-dotnav > * > * {
  border-color: $white;
}
.uk-dotnav > .uk-active > * {
  background-color: $white;
}
